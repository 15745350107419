import MenuLateral from "../../components/menu-lateral"

function Apadrinhar() {
    return (
        <div className='container'>
            <div className='dashboard'>
                <MenuLateral />

                <div className='container-painel'>
                    <h1>Apadrinhar </h1>
                </div>
            </div>
        </div>
    )
}

export default Apadrinhar
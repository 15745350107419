function NotFound(){
    return(
        <div className='containerPai_NF'>
            <div className="container-404">
                
                <h1>Ainda não temos essa página</h1>
                <p>Estamos trabalhando nisso! Volte em breve para conferir.</p>
            </div>
        </div>
    )
}

export default NotFound
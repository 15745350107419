import Menu from '../navegador-botoes'

function Cabecalho() {

    return(    
        <div className='container-cabecalho'>
            <Menu/>            
        </div>
    )
}

export default Cabecalho